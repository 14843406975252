<template>
  <div class="payDetails">
    <el-tabs v-model="listState" @tab-click="filterFun">
      <el-tab-pane
        v-for="item in listStateList"
        :key="item.id"
        :label="item.value"
        :name="item.id+''"
      ></el-tab-pane>
    </el-tabs>

    <div class="filter-container">
      <div class="filter-item">
        <label class="label" style="width: 75px;display: inline-block;text-align: right;">关键字:</label>
        <el-select
          v-model="KeyWordsType"
          placeholder="请选择"
          style="width: 160px;margin-right: 10px;"
        >
          <el-option :value="0" label="推手信息"></el-option>
          <el-option :value="1" label="订单编号" v-if="listState==0"></el-option>
          <el-option :value="1" label="退款编号" v-if="listState==1"></el-option>
        </el-select>
        <el-input
          @keyup.enter.native="doorListFilter"
          v-model="KeyWords"
          :placeholder="KeyWordsType==0 ? '姓名、微信昵称、手机号' : (listState==0?'订单编号' : '退款单号')"
          style="width: 250px;"
          clearable
        ></el-input>
      </div>

      <div class="filter-item">
        <label class="label" v-show="listState==0">支付时间:</label>
        <label class="label" v-show="listState==1">退款时间:</label>
        <el-date-picker
          type="datetime"
          placeholder="选择日期"
          v-model="StartTime"
          style="width: 250px;"
          value-format="yyyy-MM-dd HH:mm:ss"
          format="yyyy-MM-dd HH:mm:ss"
        ></el-date-picker>~
        <el-date-picker
          type="datetime"
          placeholder="选择日期"
          v-model="EndTime"
          default-time="23:59:59"
          style="width: 250px;"
          value-format="yyyy-MM-dd HH:mm:ss"
          format="yyyy-MM-dd HH:mm:ss"
        ></el-date-picker>
      </div>

      <div class="filter-item">
        <div class="filter-item">
          <label class="label">推手级别:</label>
          <el-select v-model="DistibutorRoleId">
            <el-option label="全部" :value="null"></el-option>
            <el-option
              v-for="item in gradeOptions"
              :key="item.Id"
              :label="item.DistributRoleName"
              :value="item.Id"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="filter-item">
        <label class="label">订单来源:</label>
        <el-select v-model="OrderFrom">
          <el-option v-for="item in OrderFromlist" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </div>

      <div class="filter-item">
        <el-button type="primary" style="margin-left:30px;width:90px;" @click="doorListFilter">查询</el-button>
        <el-button style="margin-left:30px;width:90px;" @click="exportFun">导出</el-button>
      </div>
    </div>
    <!-- 订单表格 -->
    <div class="table-container" v-show="listState== 0">
      <div class="totalStyle">
        <span>销售总额（元）：{{SellTotal}}</span>
        <br />
        <span class="introduce">通过推手的链接支付成功的订单金额，包含运费，包含推手自消费的订单，不剔除已关闭或已退款数据</span>
      </div>
      <el-table :data="groupData" v-loading="loading">
        <el-table-column label="推手" width="220">
          <template slot-scope="scope">
            <div>{{scope.row.Name==''? scope.row.WxNickname:scope.row.Name}}</div>
            <div>{{scope.row.Phone}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="DistributRoleName" label="推手级别"></el-table-column>
        <el-table-column prop="OrderNo" label="订单" width="250">
          <template slot-scope="scope">
            <el-button @click="openNewOrderNo(scope.row)" type="text">{{scope.row.OrderNo}}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="OrderStateValue" label="订单状态"></el-table-column>
        <el-table-column prop="PayMoney" label="支付金额"></el-table-column>
        <el-table-column prop="PayTime" label="支付时间"></el-table-column>
      </el-table>
      <div style="text-align: right;">
        <el-pagination
          v-if="Total"
          style="margin:20px 0"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 40, 50]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="Total"
        ></el-pagination>
      </div>
    </div>

    <!-- 退款单表格 -->
    <div class="table-container" v-show="listState== 1">
      <div class="totalStyle">
        <span>退款总额（元）：{{RefundTotal}}</span>
        <br />
        <span class="introduce">通过推手的链接支付成功的订单金额，退款成功的金额，包含退回的运费</span>
      </div>
      <el-table :data="groupData" v-loading="loading">
        <el-table-column label="推手" width="220">
          <template slot-scope="scope">
            <div>{{scope.row.Name==''? scope.row.WxNickname:scope.row.Name}}</div>
            <div>{{scope.row.Phone}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="DistributRoleName" label="推手级别"></el-table-column>
        <el-table-column prop="SupportNo" label="退款单" width="250">
          <template slot-scope="scope">
            <el-button
              @click="openNewSupportNo(scope.row)"
              type="text"
            >{{scope.row.SupportNo}}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="RealRefundMoney" label="退款金额"></el-table-column>
        <el-table-column prop="CompleteTime" label="退款时间"></el-table-column>
      </el-table>
      <div style="text-align: right;">
        <el-pagination
          v-if="Total"
          style="margin:20px 0"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 40, 50]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="Total"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { distfilterBoxList } from "@/api/wyUsedInterface.js";
import apiList from "@/api/other";
import config from "@/config/index";
export default {
  data() {
    return {
      MemberId: null,
      KeyWordsType: 0,
      KeyWords: "",
      DistibutorRoleId: null,
      StartTime: "",
      EndTime: "",
      OrderFrom:0,
      OrderFromlist:[
        {
          value:0,
          label:'小程序商城订单'
        },{
          value:1,
          label:'视频号小店订单'
        },
      ],
      loading: false,
      listStateList: [
        {
          id: '0',
          value: "销售单"
        },
        {
          id: 1,
          value: "退款单"
        }
      ],
      gradeOptions: [],
      listState: "0",
      currentPage: 1,
      pageSize: 20,
      Total: 0,
      groupData: [],
      exportUrl: config.EXPORT_URL,
      goUrls: config.GO_URL,
      SellTotal: 0,
      RefundTotal: 0
    };
  },
  created() {
    this.getAuthList();
  },
  beforeMount() {
		this.initTime();
		this.KeyWords = this.$route.query.KeyWords ? this.$route.query.KeyWords : "";
		if(this.$route.query.KeyWords){
			this.StartTime = this.$route.query.starTime;
      this.EndTime = this.$route.query.endTime;
      this.listState  = this.$route.query.listState + '';
      this.OrderFrom = Number(this.$route.query.OrderFrom)
		}
		
    this.getList();
  },
  methods: {
    async getAuthList() {
      try {
        let result = await distfilterBoxList();
        this.gradeOptions = result.Result;
      } catch (e) {
      }
    },
    //跳转到订单详情
    openNewOrderNo(row) {
      let OrderNoId = row.OrderNo ? row.OrderNo : 0;
      let headsUrls = process.env.NODE_ENV == "production" ? "index.html#" : "";
      let url =
        this.goUrls + headsUrls + (this.OrderFrom==0?'/order/orderDetailpage':'/videoShop/videoOrderDetails')+'?Id=' + OrderNoId;
      window.open(url);
    },
    openNewSupportNo(row) {
      let SupportNoId = row.SupportNo ? row.SupportNo : 0;
      let headsUrls = process.env.NODE_ENV == "production" ? "index.html#" : "";
      let url = this.goUrls + headsUrls + (this.OrderFrom==0?"/order/refundAfterSale/refundDetails":"/videoShop/videoAfterSaleDetails")+"?id=" + row.SupportNo;
      window.open(url);
    },
    async getList() {
      try {
        this.loading = true;
        let data = {
          MemberId: this.MemberId,
          KeyWords: this.KeyWords,
          KeyWordsType: this.KeyWordsType,
          StartTime: this.StartTime,
          EndTime: this.EndTime,
          OrderFrom:this.OrderFrom,
          DistibutorRoleId: this.DistibutorRoleId,
          Skip: (this.currentPage - 1) * this.pageSize, // 略过数据
          Take: this.pageSize, // 取的数据
        };
        let result = {};
        if (this.listState == 0) {
          result = await apiList.memberDistributorSellDetail(data);
          let result1 = await apiList.memberDistributorSellTotal(data);
          this.SellTotal = result1.Result.SellMoney;
        } else {
          result = await apiList.memberDistributorRefundDetail(data);
          let result2 = await apiList.memberDistributorRefundTotal(data);
          this.RefundTotal = result2.Result.RefundMoney;
        }
        this.Total = result.Result.Total;
        this.groupData = result.Result.Results || [];
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    filterFun() {
      this.MemberId = null;
      // this.KeyWordsType = 0;
      // this.KeyWords = "";
      this.DistibutorRoleId = null;
      this.OrderFrom = 0
      this.currentPage = 1;
      this.pageSize = 20;
      this.getList();
    },
    initTime() {
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1 > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1);
      let day = date.getDate() > 9 ? date.getDate() : "0" + date.getDate();
      this.StartTime = year + "-" + month + "-" + "01" + " " + "00:00:00";
      this.EndTime = year + "-" + month + "-" + day + " " + "23:59:59";
    },
    // 查询
    doorListFilter() {
      this.currentPage = 1;
      this.getList();
    },
    exportFun() {
      let api =
        this.listState == 0
          ? "/pc/member/memberDistributorSellDetailExport"
          : "/pc/member/memberDistributorRefundDetailExport";
      let url =
        this.exportUrl + api + "?" +
        "&MemberId=" + (this.MemberId ? this.MemberId : "") +
        "&KeyWordsType=" + this.KeyWordsType +
        "&KeyWords=" + this.KeyWords +
        "&StartTime=" + (this.StartTime ? this.StartTime : "") +
        "&EndTime=" + (this.EndTime ? this.EndTime : "") +
        "&DistibutorRoleId=" + (this.DistibutorRoleId ? this.DistibutorRoleId : "") + 
				"&OrderFrom=" + this.OrderFrom 
      window.open(url);
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },

    handleCurrentChange(val) {
      this.currentPage = val;
      this.getList();
    }
  }
};
</script>

<style lang="less" scoped>
.payDetails {
  background: #fff;
  padding: 15px;

  .totalStyle {
    background: #f5f5f5;
    padding: 13px;
    font-size: 15px;
    .introduce {
      color: #909399;
      font-size: 12px;
    }
  }
}
</style>
